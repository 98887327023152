<template>

    <div class="container">
          <div style="min-height: 400px" class="row ">

            <div class="col-md-2 col-sm-2 ">
             <sidebar/>
           </div>

          <div class="col-md-10 col-lg-10 col-xs-12">
              <div class="form-container content">
             <div  class="card">
                   <div class="card-heading text-center ">
                       <h4 class="card-title">Change current password </h4>
                  </div>
                  <div class="card-body ">
                        <form @submit.prevent="changePassword" class="form">

                          <div  class="form-group mb-3 ">
                                <label class="form-label">Current Password</label>
                                <input type="password" required v-model="form.old_password" :class="{'is-invalid' : form.errors.has('old_password') }" name="old_paasword" autocomplete="off" placeholder="current password" class="form-control" >
                                <has-error :form="form" field="old_password "> </has-error>
                          </div>

                          <div class="form-group mb-3">
                                <label class="form-label">New Password</label>
                                <input type="password" required v-model="form.new_password" :class="{'is-invalid' : form.errors.has('new_password') }" name="new_paasword" autocomplete="off" placeholder="new password" class="form-control" >
                                <has-error :form="form" field="new_password "> </has-error>
                          </div>

                         <div class="form-group mb-3 text-center ">
                              <button  :disabled="form.busy" class="btn btn-sm btn-primary " type="submit"> <i class="fa fa-spiner" v-if="form.busy"></i> Change</button>
                         </div>
                      </form>

                  </div>
             </div>
          </div>
        </div>

     </div>
    </div>


</template>

<script>

import Vue from "vue";
import { Form, HasError } from "vform";
import ObjectToFD from 'vue-object-to-formdata';
Vue.use(ObjectToFD);
import sidebar from "../../components/Sidebar.vue"

export default {

  data() {
    return {
      form: new Form({
        old_password: "",
        new_password: "",
        user_token:localStorage.getItem('user_token'),
      }),
    };
  },

  methods: {
    changePassword() {
      this.form
        .post("/user/password/update",this.$objectToFD(this.form) )
        .then((resp) => {
        //  console.log(resp);
          if (resp.data.success == "OK") {
            this.$toast.success(resp.data.message);
            this.$router.push({ name: "UserDashboard" });
          } else {
            this.$toast.error(resp.data.message);
          }
        })
    },
  },

  components:{
      sidebar,
      HasError
  }
};
</script>

<style scoped>

 .form-container{
   margin: 0% 20%;
   width: 60%;
   height: 330px;
   margin-bottom: 50px;
   margin-top: 26px;
   box-shadow:1px 1px 5px 3px #ddd;
 }

 .card{
  padding-top: 20px;
}

.content{
  background:#fff;
  padding: 20px 20px;
}
</style>
